import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpStatusCode } from '../shared/enums/sharedenums.options';
import { ErrorsService } from '../shared/services/errors.service';
import { StorageService } from './storage.service';

export enum UserType {
    Unknown = 0,
    Admin = 1,
    Partner = 2,
    Scanner = 3,
    Person = 4,
    Franchise = 5,
    TemporaryPerson = 6,
    SalePortal = 7,
    Operator = 8,
    Customer = 9,
    OrderReciver = 11,
    Developer = 12,
    Subadmin = 13
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authUrl: string = environment.identityUrl;
    wellknown: string = environment.wellknown;
    endpointToken: string;
    authorized: BehaviorSubject<boolean>;
    authorizedMessage: string;
    isAuthorized = false;

    myStorage: StorageService;
    private readonly _clientScopes: string[] = [
        'openid',
        'profile',
        'accounting',
        'offline_access',
        'virtualcard',
        'client',
        'partner',
        'employee',
        'locations',
        'filestore',
        'userprovider',
        'cardgenerator',
        'advertisement',
        'loyaltyservices',
        'catalog',
        'translation',
        'talkurl',
        'orders',
        'feedback',
        'basket',
        'orders.signalrhub',
        'hotactions',
        'subscriptions',
        'imagecloud',
        'smarttranslate',
        'payment',
        'webhook',
        'webboneeagg',
        'udefsettings',
        'appintegrationsscope',
        'saleportal',
        'dataprocessing',
        'theme',
        'externalintegration',
        'pos',
        'iikocloud',
        'triosoft'
    ];

    constructor(
        private httpClient: HttpClient,
        storageService: StorageService,
        private appInsightsService: AppInsightsService,
        private errorService: ErrorsService,
        private router: Router
    ) {
        this.myStorage = storageService;
        this.authorized = new BehaviorSubject(this.checkTokenValidation());
        this.errorService.error.subscribe(error => {
            if (error.code === HttpStatusCode.Unauthorized) {
                this.authError('sessionEndError');
            }
        });
        this.setEndpointToken();
    }

    checkTokenValidation(): boolean {
        const access_token = this.getStoredItem('access_token');
        const expires_in = this.getStoredItem('expires_in');
        if (typeof access_token === 'undefined' || access_token === null || access_token === '') {
            this.isAuthorized = false;
            return false;
        }

        if (typeof expires_in === 'undefined' || expires_in === null) {
            this.isAuthorized = false;
            return false;
        }

        if (parseInt(expires_in, 10) > Date.now()) {
            this.isAuthorized = true;
            // this.authorized.next(true);
            return true;
        }

        const refresh_token = this.getStoredItem('refresh_token');
        if (typeof refresh_token === 'undefined' || refresh_token === null || refresh_token === '') {
            this.isAuthorized = false;
            return false;
        }
        return false;
    }

    setEndpointToken() {
        const endpoint = this.getStoredItem('endpointToken');
        if (
            typeof endpoint !== 'undefined' &&
            endpoint !== null &&
            endpoint.startsWith(environment.identityUrl)
        ) {
            this.endpointToken = endpoint;
        } else {
            this.getEndpointToken().subscribe(data => {
                this.endpointToken = data[0];
                this.saveItemToStorage('endpointToken', this.endpointToken);
            });
        }
    }

    getEndpointToken(): Observable<Array<string>> {
        return this.httpClient.get(this.authUrl + this.wellknown).pipe(
            map(res => [res['token_endpoint']],
                err => {
                    console.log('Error receiving endpoint:' + err);
                }));
    }

    refreshToken(refreshToken: string) {
        const form = new FormData();
        form.append('grant_type', 'refresh_token');
        form.append('client_id', 'vcmclient');
        form.append('refresh_token', refreshToken);

        this.requestAndSaveToken(form);
    }

    getAccessToken(name: string, pass: string) {
        const form = new FormData();
        form.append('grant_type', 'password');
        form.append('scope', this._clientScopes.join(' '));
        form.append('client_id', 'vcmclient');
        form.append('username', name);
        form.append('password', pass);

        this.requestAndSaveToken(form);

    }

    requestAndSaveToken(data: FormData) {
        this.httpClient.post(this.endpointToken, data)
            .subscribe(res => {
                const respJson = <any>res;
                let authorized = true;
                let decoded: any;
                try {
                    decoded = jwt_decode<any>(respJson.access_token);
                    let roles: string[] = [];
                    if (Array.isArray(decoded.role)) {
                        roles = decoded.role as string[];
                    } else {
                        roles = [(decoded.role as string)];
                    }
                    authorized = 
                    roles.includes('Partner') || 
                    roles.includes('Admin') || 
                    roles.includes('SalePortal') ||
                    roles.includes('Subadmin');
                } catch (error) {
                    authorized = false;
                }
                if (authorized) {
                    this.saveTokenInfo(
                        respJson.access_token,
                        respJson.refresh_token,
                        respJson.signalr_token,
                        respJson.expires_in);
                    this.authorizedMessage = '';
                    this.isAuthorized = true;
                    this.authorized.next(true);
                    this.appInsightsService.setAuthenticatedUserContext(decoded.sub);
                } else {
                    this.authError('authorizefailed');
                }
            },
                err => {
                    this.authError('authorizefailed');
                });
    }

    saveTokenInfo(access_token: string, refresh_token: string, signalr_token: string, date_exp: number) {
        this.saveItemToStorage('access_token', access_token);
        this.saveItemToStorage('refresh_token', refresh_token);
        this.saveItemToStorage('signalr_token', signalr_token);
        // gap before token will expire
        const trustedInterval = 100;
        let exp = -1;
        if (date_exp > trustedInterval) {
            exp = (Date.now() + (date_exp - trustedInterval) * 1000);
        } else {
            exp = date_exp;
        }

        // store expire date in miliseconds
        this.saveItemToStorage('expires_in', exp.toString());
    }

    saveItemToStorage(key: string, token: string) {
        this.myStorage.store(key, token);
    }

    getStoredItem(key: string): string {
        return this.myStorage.retrieve(key);
    }

    logout() {
        if (this.checkTokenValidation()) {
            this.myStorage.clearStorageKeys();
            this.saveTokenInfo('', '', '', -1);
            this.isAuthorized = false;
            return;
        }
        this.isAuthorized = false;
        this.authorized.next(false);
        this.appInsightsService.clearAuthenticatedUserContext();
    }

    private authError(errorMessage: any) {
        if (this.router.url === '/profile/registration') {
            this.myStorage.clearStorageKeys();
            this.saveTokenInfo('', '', '', -1);
            return;
        }
        this.authorizedMessage = errorMessage;
        this.logout();
        this.router.navigate(['/profile/login']);
        console.log('error recieving token: ' + errorMessage);
    }

    allowUseEmail(email: string): Observable<boolean> {
        const encoded_email = encodeURIComponent(email);
        const res = this.httpClient.get<boolean>(this.authUrl + '/account/allowuseemail?email=' + encoded_email);
        return res;
    }
    allowUsePhone(phone: string, phoneCode: string): Observable<boolean> {
        if (!phone) {
            return from([false]);
        }
        if (!phoneCode) {
            return from([false]);
        }
        phoneCode = phoneCode?.replace('+', '') ?? '';
        const encoded_phone = encodeURIComponent(phone);
        const encoded_phone_code = encodeURIComponent(phoneCode);
        const url = `${this.authUrl}/account/allowusephone?phone=${encoded_phone}&phoneCode=${encoded_phone_code}`;
        console.log(url);
        const res = this.httpClient.get<boolean>(url);
        return res;
    }
}
